import React from 'react'
import { Svg } from '@apeswapfinance/uikit'

const RightArrowIcon = (props) => {
  return (
    <Svg
      viewBox="0 0 24 24"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLineJoin="round"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="5 3 19 12 5 21 5 3"></polygon>
    </Svg>
  )
}

export default RightArrowIcon
