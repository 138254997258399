import { BANANA_PER_BLOCK } from 'config'
import { PoolConfig, PoolCategory } from './types'
import tokens from './tokens'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'CORK',
    image: 'CORK.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.banana,
    contractAddress: {
      // 97: '0xEBb34AE301082759B9DaadB2c1AcDEd675C6B360', // MasterApe
      56: '0x21694642bea2D2E0B0f5129a25D753dd9fB9623A',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://winery.finance/',
    harvest: true,
    tokenPerBlock: `${BANANA_PER_BLOCK.toNumber() * 0.25}`,
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 1,
    tokenName: 'BNB',
    image: 'BFT.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.Reward1,
    contractAddress: {
      56: '0x57bC0e27660129Fb2795192bcE756FF1e227d9B4', // ApeRewardPool
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.bitfresh.win',
    harvest: true,
    tokenPerBlock: `0.00479442180800000`,
    sortOrder: 2,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  },
  {
    sousId: 2,
    tokenName: 'BUSD',
    image: 'BFT.svg',
    stakingToken: tokens.banana,
    rewardToken: tokens.Reward1,
    contractAddress: {
      56: '0xc0256bD8155A53e832fe79459327aE114Edd8Fc9', // ApeRewardPool
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://www.bitfresh.win',
    harvest: true,
    tokenPerBlock: `0.00479442180800000`,
    sortOrder: 3,
    reflect: false,
    isFinished: true,
    tokenDecimals: 18,
  }
  // {
  //   sousId: 21,
  //   tokenName: 'BANANA',
  //   image: 'BANANA.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.banana,
  //   contractAddress: {
  //     97: '0x296df4Dc67e543f419D1Bf0636B8F898dB458b42', // ApeRewardPool
  //     56: '0x8F97B2E6559084CFaBA140e2AB4Da9aAF23FE7F8',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://apeswap.finance/apezone',
  //   harvest: true,
  //   tokenPerBlock: `0.150000000000000000`,
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 17,
  //   tokenName: 'R1',
  //   stakingToken: tokens.banana,
  //   image: 'SWAMP.svg',
  //   rewardToken: tokens.Reward1,
  //   contractAddress: {
  //     // BEP20RewardApe
  //     97: '0x296df4Dc67e543f419D1Bf0636B8F898dB458b42',
  //     56: '0xA007a5a8165D70DdFBC230927Cd639163228Fc72',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://swamp.finance',
  //   harvest: true,
  //   tokenPerBlock: '0.00479442180800000',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   lpStaking: true,
  // },

  // {
  //   sousId: 19,
  //   tokenName: 'ALLOY',
  //   stakingToken: tokens.banana,
  //   image: 'ALLOY.png',
  //   rewardToken: null,
  //   contractAddress: {
  //     // BEP20RewardApe
  //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  //     56: '0x834534853bff9C97F8A9b0fb9FFF864E3584Df99',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://hyperjump.fi',
  //   harvest: true,
  //   tokenPerBlock: '0.01467673032',
  //   sortOrder: 5,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   lpStaking: false,
  // },
  // {
  //   sousId: 20,
  //   tokenName: 'NAUT',
  //   stakingToken: tokens.banana,
  //   image: 'NAUT.png',
  //   rewardToken: null,
  //   contractAddress: {
  //     // BEP20RewardApe
  //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  //     56: '0xDC0913b2bf505E03794f0A68a0DbBa333D8e0E01',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://astronaut.to',
  //   harvest: true,
  //   tokenPerBlock: '0.03858024691358',
  //   sortOrder: 5,
  //   isFinished: true,
  //   tokenDecimals: 8,
  //   lpStaking: false,
  // },
  // // {
  // //   sousId: 18,
  // //   tokenName: 'NAUT',
  // //   stakingTokenName: 'BNB-NAUT',
  // //   image: 'NAUT.png',
  // //   rewardToken: null,
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0x288ea5437c7aad045a393cee2f41e548df24d1c8', // BNB-NAUT LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0x330de89a559a75738acB5b87aE93579120d53AfB',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://astronaut.to',
  // //   harvest: true,
  // //   tokenPerBlock: '0.049603174603175',
  // //   sortOrder: 6,
  // //   isFinished: true,
  // //   tokenDecimals: 8,
  // //   lpStaking: true,
  // // },
  // {
  //   sousId: 12,
  //   tokenName: 'JDI',
  //   stakingToken: tokens.bnbjdi,
  //   image: 'JDI.svg',
  //   rewardToken: tokens.jdi,
  //   contractAddress: {
  //     // BEP20RewardApe
  //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  //     56: '0x7336B60aC64a5Bf04Eec760bD135ea105994387C',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://jdiyield.com',
  //   harvest: true,
  //   tokenPerBlock: '0.07716049382716',
  //   sortOrder: 6,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   lpStaking: true,
  // },
  // {
  //   sousId: 28,
  //   tokenName: 'JDI',
  //   image: 'JDI.svg',
  //   stakingToken: tokens.jdi,
  //   rewardToken: tokens.jdi,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0xa3283C497710cbBE31d35A5cf56C315C621D6e10', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://jdiyield.com',
  //   harvest: true,
  //   tokenPerBlock: `0.11574074074`,
  //   sortOrder: 3,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   reflect: true,
  // },
  // {
  //   sousId: 13,
  //   tokenName: 'FOXY',
  //   stakingToken: tokens.banana,
  //   image: 'FOXY.png',
  //   rewardToken: null,
  //   contractAddress: {
  //     // BEP20RewardApe
  //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  //     56: '0x726d5A4D170425C98BAff117BF3d9aF9f77f80bd',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://foxynft.org',
  //   harvest: true,
  //   tokenPerBlock: '0.05787037037037',
  //   sortOrder: 4,
  //   isFinished: true,
  //   tokenDecimals: 9,
  //   lpStaking: false,
  // },
  // {
  //   sousId: 14,
  //   tokenName: 'JDI',
  //   stakingToken: tokens.banana,
  //   image: 'JDI.svg',
  //   rewardToken: tokens.jdi,
  //   contractAddress: {
  //     // BEP20RewardApe
  //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  //     56: '0x72C8750af0744282E065F0dF3F73460A3764c624',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://jdiyield.com',
  //   harvest: true,
  //   tokenPerBlock: '0.115640358485111',
  //   sortOrder: 3,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   lpStaking: false,
  // },
  // {
  //   sousId: 16,
  //   tokenName: 'LYPTUS',
  //   image: 'LYPTUS.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     // BEP20RewardApe
  //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  //     56: '0x1900f5370830F0aa46DF76a6a4F0656Ef321b9f8',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://koaladefi.finance/',
  //   harvest: true,
  //   tokenPerBlock: '0.034722222222222',
  //   sortOrder: 3,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   lpStaking: false,
  // },
  // {
  //   sousId: 22,
  //   tokenName: 'ONT',
  //   image: 'ONT.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7', // ApeRewardPool
  //     56: '0x8e6f61f855a7483E29c8E87BF221C73e10b49738',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://ont.io',
  //   harvest: true,
  //   tokenPerBlock: `0.009645061728000000`,
  //   sortOrder: 2,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 24,
  //   tokenName: 'BFT',
  //   image: 'BFT.svg',
  //   stakingToken: tokens.bft,
  //   rewardToken: tokens.bft,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7', // ApeRewardPool
  //     56: '0x49bbB345c432b9B66b407f1525A81A84F4509b8b',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.bitfresh.win',
  //   harvest: true,
  //   tokenPerBlock: `3.858024691358025`,
  //   sortOrder: 1.01,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 25,
  //   tokenName: 'BFT',
  //   image: 'BFT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7', // ApeRewardPool
  //     56: '0x5798271B134e27c4dE28CB33aa8D18e5753e83fC',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.bitfresh.win',
  //   harvest: true,
  //   tokenPerBlock: `5.787037037`,
  //   sortOrder: 3,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 26,
  //   tokenName: 'SAFEP',
  //   image: 'SAFEP.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0xEdea59e33A6D0A2d491Aa818f6E08ea18DE13c32', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://safeprotocol.io',
  //   harvest: true,
  //   tokenPerBlock: `11.5740740741`,
  //   sortOrder: 3,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 8,
  // },
  // {
  //   sousId: 27,
  //   tokenName: 'GEN',
  //   image: 'GEN.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x6FbB19A87f1E86f027A084C8bfc3528120Cf8249', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://evodefi.com',
  //   harvest: true,
  //   tokenPerBlock: `0.024801587301587`,
  //   sortOrder: 3,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 29,
  //   tokenName: 'BINGUS',
  //   image: 'Bingus.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x1697b103a72299d5db4e39ef28a32991c347be23', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'http://bingus.finance/',
  //   harvest: true,
  //   tokenPerBlock: `3592.308202022`,
  //   sortOrder: 3,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 31,
  //   tokenName: 'vBSWAP',
  //   image: 'VBSWAP.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x7124d635a4bb82319acfd57ce9da18137a7a6f22', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://valuedefi.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.000017361111`,
  //   sortOrder: 6,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 32,
  //   tokenName: 'WATCH',
  //   image: 'WATCH.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x465a3782d9014e6eefccc21470c5e41b2cad9d6d', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.yieldwatch.net/',
  //   harvest: true,
  //   tokenPerBlock: `0.015432098765432098`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 33,
  //   tokenName: 'KEYFI',
  //   image: 'KEYFI.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0xEedd7475Eb5D05D591bE0927B178AcBBdC5ee1c1', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://keyfi.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.025483796296296296`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 34,
  //   tokenName: 'BFT',
  //   image: 'BFT.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.bft,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x73775564d2cbad604c41d0b3ddf061e80e1d0279', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.bitfresh.win',
  //   harvest: true,
  //   tokenPerBlock: `1.9290123456790123`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 35,
  //   tokenName: 'NRV',
  //   image: 'NRV.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x48ee3f7748fac3e8e4858bd0b09483c4339d3d7e', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.nerve.fi',
  //   harvest: true,
  //   tokenPerBlock: `0.06448412698412699`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 36,
  //   tokenName: 'MOONLIGHT',
  //   image: 'MOONLIGHT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0xe44d4786e9d367a475a0789dc618c76a2b2d05ec', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://moonlighttoken.com/',
  //   harvest: true,
  //   tokenPerBlock: `15098621`,
  //   sortOrder: 3,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 38,
  //   tokenName: 'TAPE',
  //   image: 'TAPE.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x4b5ee00fcce905cc2861c8255b368cf9f114c667', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://apetools.co/',
  //   harvest: true,
  //   tokenPerBlock: `2.314814814814815`,
  //   sortOrder: 3,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 64,
  //   tokenName: 'NALIS',
  //   image: 'NALIS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x063e98d9f0484c07028eb78661df554a064aeb05',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://koaladefi.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.028935185185185185`,
  //   sortOrder: 2,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 39,
  //   tokenName: 'SPACE',
  //   image: 'SPACE.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x52634C2763a6DADA2028440d0892431b04809d88', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://aperocket.finance',
  //   harvest: true,
  //   tokenPerBlock: `0.009902199074074074`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 40,
  //   tokenName: 'pCWS',
  //   image: 'pCWS.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x1C62ac4D59FFACb35BFfcDb9fcd8b368a528B544', // pCWS
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://game.seascape.network/',
  //   harvest: true,
  //   tokenPerBlock: `0.011574074074074074`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 41,
  //   tokenName: 'TYPH',
  //   image: 'TYPH.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x222c821620480f7482238dd3B71A7051d02c9624',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://typhoon.network/',
  //   harvest: true,
  //   tokenPerBlock: `0.413359788359788350`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 42,
  //   tokenName: 'bMXX',
  //   image: 'bMXX.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x73dAF9AfEBB271007167f3f0D140a35f5b1a7058',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://multiplier.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.014467592592592593 `,
  //   sortOrder: 3,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 44,
  //   tokenName: 'CRUSH',
  //   image: 'CRUSH.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.crush,
  //   contractAddress: {
  //     97: '0xa439dec59e6ef0ef2c03564fcd72b181054e3e60',
  //     56: '0xAC4409CC7709783c7CCEb6c6e69Fe42fAD192623',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.bitcrush.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.2314816600938735`,
  //   sortOrder: 4,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 45,
  //   tokenName: 'GRAND',
  //   image: 'GRAND.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0x52F5f16F90B897AB9C2c7d3028FEF9e11B2BB485',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.thegrandbanks.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.000231481481481481`,
  //   sortOrder: 5,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 46,
  //   tokenName: 'GMR',
  //   image: '',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x0e34e04310e44329f1dafd28c9922ee4517ac397',
  //     56: '0xfd9c606d3Ff6AC1b044C4F5C0A9Fa934634bD2e8',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.gmr.finance/',
  //   harvest: true,
  //   tokenPerBlock: `1257518.026451967`,
  //   sortOrder: 6,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 47,
  //   tokenName: 'WINGS',
  //   image: 'WINGS.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x15C4200e694e4A0223191Ec717906CBA82F54745',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://jetswap.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.009156250000000000`,
  //   sortOrder: 6,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 48,
  //   tokenName: 'HERO',
  //   image: 'HERO.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xc417D9D9b01b5985c1720cA89B963c2417821bdC',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://farmhero.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.5787037037037037`,
  //   sortOrder: 6,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 49,
  //   tokenName: 'FOXY',
  //   image: 'FOXY.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x6D5A4371B0B658765D5282Ae64eFaf9e6f9A4600',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://foxynft.org/',
  //   harvest: true,
  //   tokenPerBlock: `0.289351851`,
  //   sortOrder: 6,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 50,
  //   tokenName: 'MBOX',
  //   image: 'MOBOX.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x19383690321291cb00de31ddec94f4596d504f5a',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.mobox.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.1830439814814815`,
  //   sortOrder: 3,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 51,
  //   tokenName: 'WYVERN',
  //   image: 'WYVERN.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x33a8CE49cFBdeF61e5E494f769b5626d395a56f3',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://wyverntoken.com/',
  //   harvest: true,
  //   tokenPerBlock: `1352.941671296`,
  //   sortOrder: 5,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 52,
  //   tokenName: 'BOG',
  //   image: 'BOG.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x1851a14b9b6bcd55b57da6bfd11f7fb5ae8c3d01',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://bogged.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.05787037037037037`,
  //   sortOrder: 4,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 54,
  //   tokenName: 'HIFI',
  //   image: 'HIFI.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x4fa77345de3d991625cbf3317cb449f729378d34',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://hifigamingsociety.com',
  //   harvest: true,
  //   tokenPerBlock: `52.083333333333333333`,
  //   sortOrder: 4,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 55,
  //   tokenName: 'LORY',
  //   image: 'LORY.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.lory,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x14e87345dd641a222a353d1fb0de5f0e66d0668e',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://yieldparrot.finance',
  //   harvest: true,
  //   tokenPerBlock: `0.031348765432098765`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 56,
  //   tokenName: 'SCAM',
  //   image: 'SCAM.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x49605d4d0bdf049a9c3f20f59e066662a086a9a8',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://scamily.io/',
  //   harvest: true,
  //   tokenPerBlock: `1666.6666666666`,
  //   sortOrder: 4,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 10,
  // },
  // {
  //   sousId: 57,
  //   tokenName: 'FRUIT',
  //   image: 'FRUIT.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x98cb2265f54d8aed72ea78d207f068961f0630a4',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.fruitsadventures.com/',
  //   harvest: true,
  //   tokenPerBlock: `11.574189814814814814`,
  //   sortOrder: 3,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 58,
  //   tokenName: 'SKILL',
  //   image: 'SKILL.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x92f5927fb750593b00ac933d296d9230d6f8b421',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.cryptoblades.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.11574074074074074`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 59,
  //   tokenName: 'GNT',
  //   image: 'GNT.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xfa1e8344e8316b4c7ca4d9f9359154bd3b42b427',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.greentrusttoken.com/',
  //   harvest: true,
  //   tokenPerBlock: `5260.942760416666`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 60,
  //   tokenName: 'PACOCA',
  //   image: 'PACOCA.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x32d5dae00cf9ec718e6054cbe5f307b9e8ed80e9',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://pacoca.io',
  //   harvest: true,
  //   tokenPerBlock: `0.980902777777777777`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 61,
  //   tokenName: 'NEWB',
  //   image: 'NEWB.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x2f70e1ab58231e1981915c1b3434144dcf26868f',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.newb.farm/',
  //   harvest: true,
  //   tokenPerBlock: `0.00021875`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 63,
  //   tokenName: 'TWIN',
  //   image: 'TWIN.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x6e8cd8e267e4a8538b7119c3ca30ce04667070e5',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://twin.finance/',
  //   harvest: true,
  //   tokenPerBlock: `4.629629629629629629`,
  //   sortOrder: 63,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },

  // // IS FINISHED //
  // // CHANGE SORT ORDER TO 999 //
  // // {
  // //   sousId: 37,
  // //   tokenName: 'SPACE',
  // //   image: 'SPACE.svg',
  // //   stakingTokenName: 'SPACE-BNB',
  // //   rewardToken: null,
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0xd0F82498051067E154d1dcd3d88fA95063949D7e', // SPACE-BNB
  // //   },
  // //   contractAddress: {
  // //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  // //     56: '0xfE615dA45dBC8C763851A34Cf14d65B0056a2D8A', // ApeRewardPool
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://aperocket.finance/',
  // //   harvest: true,
  // //   tokenPerBlock: `0.02755704365`,
  // //   sortOrder: 999,
  // //   reflect: false,
  // //   isFinished: true,
  // //   tokenDecimals: 18,
  // // },
  // {
  //   sousId: 30,
  //   tokenName: 'bxBTC',
  //   image: 'bxBTC.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  //     56: '0xd65f1319f6408C03EBA7f27e156391784492A9EF', // ApeRewardPool
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://xbtc.fi/',
  //   harvest: true,
  //   tokenPerBlock: `0`,
  //   sortOrder: 999,
  //   reflect: false,
  //   isFinished: true,
  //   bonusEndBlock: 8851420,
  //   tokenDecimals: 9,
  // },

  // {
  //   sousId: 15,
  //   tokenName: 'Inactive - FOXY',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   image: 'FOXY.png',
  //   contractAddress: {
  //     // BEP20RewardApe
  //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  //     56: '0x4cb75836d3254f7454d1bf6d95448c496cbc16b1',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://foxynft.org',
  //   harvest: true,
  //   tokenPerBlock: '0.05787037037037',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 9,
  //   lpStaking: false,
  // },
  // // {
  // //   sousId: 43,
  // //   tokenName: 'HIFI',
  // //   image: 'HIFI.svg',
  // //   stakingTokenName: 'HIFI-BNB',
  // //   rewardToken: null,
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0xf093ce6778c4d7d99c23f714297fff15a661d354', // HIFI-BNB
  // //   },
  // //   contractAddress: {
  // //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7',
  // //     56: '0x454155ac563a382458eda66fdaf59237d7230dc9', // ApeRewardPool
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://hifigamingsociety.com',
  // //   harvest: true,
  // //   tokenPerBlock: `49.6031746031746`,
  // //   sortOrder: 999,
  // //   reflect: false,
  // //   isFinished: true,
  // //   tokenDecimals: 18,
  // // },

  // {
  //   sousId: 1,
  //   tokenName: 'BNB',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     // BNBRewardsApe
  //     97: '0x88777a814946cd8eb80e7029eedf05cc6cf34168',
  //     56: '0x0245c697a96045183048cdf18e9abae5b2237ff6',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://research.binance.com/en/projects/bnb',
  //   harvest: true,
  //   tokenPerBlock: '0.00069444',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   displayDecimals: 5,
  // },
  // // {
  // //   sousId: 2,
  // //   tokenName: 'SOUL',
  // //   stakingTokenName: 'BANANA-SOUL',
  // //   rewardToken: null,
  // //   image: 'SOUL.png',
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0xa48271ff51900007d3b21cecf30fdc8ccb63fee6', // BANANA-SOUL LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0xf5Cb9F954D3Ea26Bb503A6996a4b2B0aAdC8c969',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://apoyield.com',
  // //   harvest: true,
  // //   tokenPerBlock: '1384',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 8,
  // //   lpStaking: true,
  // // },
  // // {
  // //   sousId: 3,
  // //   tokenName: 'SOUL',
  // //   stakingTokenName: 'BANANA-SOUL',
  // //   rewardToken: null,
  // //   image: 'SOUL.png',
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0xa48271ff51900007d3b21cecf30fdc8ccb63fee6', // BANANA-SOUL LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0x82576dB7685418CBDD5A9f4721D605C125E4569c',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://apoyield.com',
  // //   harvest: true,
  // //   tokenPerBlock: '396',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 8,
  // //   lpStaking: true,
  // // },
  // // {
  // //   sousId: 4,
  // //   tokenName: 'NUTS',
  // //   stakingTokenName: 'BANANA-NUTS',
  // //   rewardToken: null,
  // //   image: 'NUTS.svg',
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0x44baf117d79d5313bea1fbba416e4067436e4bbe', // BANANA-NUTS LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0x3523cE00C9f82FfafC850C0Acccb78341239028b',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://squirrel.finance/',
  // //   harvest: true,
  // //   tokenPerBlock: '0.08680555556',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 18,
  // //   lpStaking: true,
  // // },
  // // {
  // //   sousId: 5,
  // //   tokenName: 'BANANA',
  // //   stakingTokenName: 'BANANA-NUTS',
  // //   rewardToken: null,
  // //   image: 'BANANA.svg',
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0x44baf117d79d5313bea1fbba416e4067436e4bbe', // BANANA-NUTS LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0xdb28A11Fe950C9979A8050E6cBA76D187D5C3b70',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://squirrel.finance/',
  // //   harvest: true,
  // //   tokenPerBlock: '0.1446527778',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 18,
  // //   lpStaking: true,
  // // },
  // // {
  // //   sousId: 6,
  // //   tokenName: 'CRX',
  // //   stakingTokenName: 'BANANA-CRX',
  // //   rewardToken: null,
  // //   image: 'CRX.svg',
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0xbd896f59baf9a624a7587de5d28b7ad3459342ba', // BANANA-CRX LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0x084beaa501dB448869001BA49913c9aD009b1694',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://cryptexlock.me/',
  // //   harvest: true,
  // //   tokenPerBlock: '0.002604166666667000',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 18,
  // //   lpStaking: true,
  // // },
  // // {
  // //   sousId: 7,
  // //   tokenName: 'NAUT',
  // //   stakingTokenName: 'BNB-NAUT',
  // //   rewardToken: null,
  // //   image: 'NAUT.png',
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0x288ea5437c7aad045a393cee2f41e548df24d1c8', // BNB-NAUT LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0x114d54e18eb4A7Dc9bB8280e283E5799D4188E3f',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://astronaut.to',
  // //   harvest: true,
  // //   tokenPerBlock: '0.28935185',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 8,
  // //   lpStaking: true,
  // // },
  // {
  //   sousId: 62,
  //   tokenName: 'BISON',
  //   image: 'BISON.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.bison,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x5a10311f7d03a6e7ab464da54bdd535f3d4ce512',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://bishares.finance',
  //   harvest: true,
  //   tokenPerBlock: `0.013778549382716049`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 23,
  //   tokenName: 'NFTL',
  //   image: 'NFTL.jpg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x3996F919980778cb0fc1982087cAc01089e33fe7', // ApeRewardPool
  //     56: '0x7618c42Fb5DC96F3C238FEC799B440AEd7c56E03',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://cybertime.finance',
  //   harvest: true,
  //   tokenPerBlock: `2.1428571429`,
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // // {
  // //   sousId: 8,
  // //   tokenName: 'NUTS',
  // //   stakingTokenName: 'BANANA-NUTS',
  // //   image: 'NUTS.svg',
  // //   rewardToken: null,
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0x44baf117d79d5313bea1fbba416e4067436e4bbe', // BANANA-NUTS LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0x6Fd37f3F83F11100f9f501e2690E96F6fAC37E94',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://squirrel.finance/',
  // //   harvest: true,
  // //   tokenPerBlock: '0.024965277777778',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 18,
  // //   lpStaking: true,
  // // },
  // // {
  // //   sousId: 10,
  // //   tokenName: 'HPS',
  // //   stakingTokenName: 'BANANA-HPS',
  // //   rewardToken: null,
  // //   image: 'HPS.png',
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0x268f002A3B8d2faC2aA2bA6D4B90d519ca0d1d46', // BNB-HPS LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0x9c64Cec2Ad86E918929665cF9AADf91cd755fEf1',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://billionhappiness.finance',
  // //   harvest: true,
  // //   tokenPerBlock: '0.014880952380952000',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 18,
  // //   lpStaking: true,
  // // },
  // // {
  // //   sousId: 11,
  // //   tokenName: 'BRICK',
  // //   stakingTokenName: 'BANANA-BRICK',
  // //   rewardToken: null,
  // //   image: 'BRICK.png',
  // //   stakingTokenAddress: {
  // //     97: '0xed89477d619c7e73f752d5fc7be60308ceb63663',
  // //     56: '0x51a9771708d0471a0e592e8febb2c6b868e3d99b', // BNB-BRICK LP
  // //   },
  // //   contractAddress: {
  // //     // BEP20RewardApe
  // //     97: '0xb1108939748A635C5ed982a17FF5C6E7D79ECF62',
  // //     56: '0x6B7254AdfCa2178B7065b4B57fDc452c405DFC8A',
  // //   },
  // //   poolCategory: PoolCategory.APEZONE,
  // //   projectLink: 'https://brickchain.finance',
  // //   harvest: true,
  // //   tokenPerBlock: '2.976190476190476',
  // //   sortOrder: 999,
  // //   isFinished: true,
  // //   tokenDecimals: 18,
  // //   lpStaking: true,
  // // },
  // {
  //   sousId: 53,
  //   tokenName: 'Inactive - HIFI',
  //   image: 'HIFI.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x8e4f6c2e8d9c5ed20c2a9a6db6553655a66d8c1b',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://hifigamingsociety.com',
  //   harvest: true,
  //   tokenPerBlock: `52.083333333333333333`,
  //   sortOrder: 999,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 65,
  //   tokenName: 'STARS',
  //   image: 'STARS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x2555190c9f515877ac88defc2242a4e91aa49021',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.mogulproductions.com/',
  //   harvest: true,
  //   tokenPerBlock: `2.893518518518518518`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 66,
  //   tokenName: 'SISTA',
  //   image: 'SISTA.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.sista,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x0a509adf33e7094f3bd15c0062debd3aef2bce28',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://srnartgallery.com',
  //   harvest: true,
  //   tokenPerBlock: `2.893518518518518518`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 67,
  //   tokenName: 'CAPS',
  //   image: 'CAPS.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x99dc608804adf5c5e9701b829fefbd618da14c5b',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.ternoa.com/',
  //   harvest: true,
  //   tokenPerBlock: `1.736111111111111111`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 68,
  //   tokenName: 'BIRB',
  //   image: 'BIRB.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xaffd843b548f70cc586cdecb2dd0e85be5349e96',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://birb.co/',
  //   harvest: true,
  //   tokenPerBlock: `5.787037037037037037`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 69,
  //   tokenName: 'DINOP',
  //   image: 'DINOP.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.dinop,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x82ee644a04573aebbfbf3d7fa98f20922a5c606a',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.dinopark.fun/',
  //   harvest: true,
  //   tokenPerBlock: `1.736111111`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 70,
  //   tokenName: 'MARU',
  //   image: 'MARU.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x51c58fb9a61c00e3e4cd09b499726ba1cd3dd261',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://riyoku.tv/',
  //   harvest: true,
  //   tokenPerBlock: `57.870370370370370370`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 71,
  //   tokenName: 'PERA',
  //   image: 'PERA.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xE6C5DAfc5BAeb927Bc56B3a4154eDeCFDbB937d6',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://pera.finance',
  //   harvest: true,
  //   tokenPerBlock: `0.462962962962962962`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 72,
  //   tokenName: 'GUARD',
  //   image: 'GUARD.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.guard,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x73d3d28cc018a89fe6518d7b5efbcfcf0695a0d9',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.wolfdencrypto.com/',
  //   harvest: true,
  //   tokenPerBlock: `4.050925925925925925`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 73,
  //   tokenName: 'LAND',
  //   image: 'LAND.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.land,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x44b7e5593dfc65208a2b68c3f6c1cffd881ef6fb',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://landshare.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.05787037037037037`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 74,
  //   tokenName: 'POTS',
  //   image: 'POTS.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x4632d4ff6312c9a00c6440c9271f0061aaa49a4b',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://moonpot.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.115740740740740740`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 75,
  //   tokenName: 'SACT',
  //   image: 'SACT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xade70b187429e3cf4a8dd476ecba8ce9002621ec',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://srnartgallery.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.462962962962962962`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 76,
  //   tokenName: 'BHC',
  //   image: 'BHC.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.bhc,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x189aE88B8Df159BDB28260E86e87D770F2FC384D',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://billionhappiness.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.002474537037000000`,
  //   sortOrder: 2,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 77,
  //   tokenName: 'CYT',
  //   image: 'CYT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.cyt,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x64fc4bf63c4315156f56b953a42bf2459b227a68',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://dragonary.com/',
  //   harvest: true,
  //   tokenPerBlock: `13.503086419753086419`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 78,
  //   tokenName: 'HOTCROSS',
  //   image: 'HOTCROSS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.hotcross,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x03f344ceb868841ffa262503fe1cf21b9cd5d7a8',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://hotcross.com/ ',
  //   harvest: true,
  //   tokenPerBlock: `0.54976851851`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 79,
  //   tokenName: 'LMT',
  //   image: 'LMT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.lmt,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x7ef515eac1cf68cde1bdbee2cda8707815e1a3c3',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://lympo.io/lmt/',
  //   harvest: true,
  //   tokenPerBlock: `0.367766203703703703`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 80,
  //   tokenName: 'FOOT',
  //   image: 'FOOT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x372c7b2c017aa055b6bd18b2d250b7dfc7593f5a',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://bigfoottoken.finance/',
  //   harvest: true,
  //   tokenPerBlock: `1087.547951388888888888`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 81,
  //   tokenName: 'NABOX',
  //   image: 'NABOX.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xae6b44c1857d48512f1cebc0f2a55e02a751969c',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://nabox.io/',
  //   harvest: true,
  //   tokenPerBlock: `3565.642041666666666666`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 82,
  //   tokenName: 'BABI',
  //   image: 'BABI.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xbc2329abb85e0a3dc7e5bfd01901374bd4162831',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://babylons.io/',
  //   harvest: true,
  //   tokenPerBlock: `1.860119047619047619`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 83,
  //   tokenName: 'REVV',
  //   image: 'REVV.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.revv,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xa38e86fa40bff9a82bb0fdcca8002f34d08f64b9',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://revvmotorsport.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.597993827160493827`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 84,
  //   tokenName: 'WIZARD',
  //   image: 'WIZARD.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.wizard,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x8440870a806e73977c5f094376a5ac8e41d9db0b',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://wizard.financial',
  //   harvest: true,
  //   tokenPerBlock: `0.039265046296296296`,
  //   sortOrder: 1,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 85,
  //   tokenName: 'GNT',
  //   image: 'GNT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.gnt,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x68741f87120e158dd8b4e972c54a6b062d3958b9',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.greentrusttoken.com/',
  //   harvest: true,
  //   tokenPerBlock: `10946.469907407407407407`,
  //   sortOrder: 1,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 86,
  //   tokenName: 'CLA',
  //   image: 'CLA.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.cla,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xc8fdda71305ff3001e84ef31f3f28460da4535ff',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.candelacoin.com/',
  //   harvest: true,
  //   tokenPerBlock: `2.480158730158730158`,
  //   sortOrder: 1,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 87,
  //   tokenName: 'PLAY',
  //   image: 'PLAY.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: null,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xb1f213615bb45d818b4579b17f25293fdccc7fda',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://polyplay.net/',
  //   harvest: true,
  //   tokenPerBlock: `0`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   emergencyWithdraw: true,
  // },
  // {
  //   sousId: 88,
  //   tokenName: 'ZIG',
  //   image: 'ZIG.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.zig,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x02f93496a75c430433a61f99c9d3f21278ee13d3',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://zignaly.com/',
  //   harvest: true,
  //   tokenPerBlock: `2.462569444444444444`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 89,
  //   tokenName: 'NVT',
  //   image: 'NVT.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.nvt,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xf45d14aa2844c14ee8a06a1bff1daa99656cf65c',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://nerve.network/',
  //   harvest: true,
  //   tokenPerBlock: `1.44675925`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 8,
  // },
  // {
  //   sousId: 90,
  //   tokenName: 'POLAR',
  //   image: 'POLAR.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.polar,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xcfaa62b60575c0808b24b2253e107fc8aa992ef4',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.polarsync.app/',
  //   harvest: true,
  //   tokenPerBlock: `0.964506172839506172`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 91,
  //   tokenName: 'GRO',
  //   image: 'GRO.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.gro,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x68696e139a7bf935285ad7d0b590ccf66ba622ba',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://growthdefi.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.006172839506172839`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 92,
  //   tokenName: 'CRUDE',
  //   image: 'CRUDE.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.crude,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x7740bb8f6be130e62597b2554aab2d69bc33c25b',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://crudeoil.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.000590277777777777`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 93,
  //   tokenName: 'CEEK',
  //   image: 'CEEK.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.ceek,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xda04ae1d4651e63c130921c6227397d58dd9167a',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://ceek.com/',
  //   harvest: true,
  //   tokenPerBlock: `15.432098765432098765`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 94,
  //   tokenName: 'KRW',
  //   image: 'KRW.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.krw,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x272d18f546CFF72D1a5bBcA9e0E25F97A71FcA1a',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://kingdefi.io/',
  //   harvest: true,
  //   tokenPerBlock: `14.467592592592592592`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 95,
  //   tokenName: 'PEAR',
  //   image: 'PEAR.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.pear,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x9ab78C2209ad2Bd50de93Aa449f1C7898DBf7138',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://pearzap.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.008714740740740740`,
  //   sortOrder: 1,
  //   reflect: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 97,
  //   tokenName: 'YDR',
  //   image: 'YDR.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.ydr,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xb5bb4897cb8024281732c36d8a438aab5b11ef19',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://ydragon.io/',
  //   harvest: true,
  //   tokenPerBlock: `1.311728395061728395`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 98,
  //   tokenName: 'ONG',
  //   image: 'ONG.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.ong,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x08b86811dcee75a37790dc28f3ad09776215ba7a',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://ont.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.050321759`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 99,
  //   tokenName: 'COPYCAT',
  //   image: 'COPYCAT.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.copycat,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x891e730df271d2c8da987651b9e7a4b9130f29b6',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.copycat.finance',
  //   harvest: true,
  //   tokenPerBlock: `0.578703703703703703`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 100,
  //   tokenName: 'BFG',
  //   image: 'BFG.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.bfg,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x2179526cfc163afb6d6cba686f0540b699028350',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://betfury.io/',
  //   harvest: true,
  //   tokenPerBlock: `1.399074074074074074`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 101,
  //   tokenName: 'DEP',
  //   image: 'DEP.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.dep,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xdbb5df464f245d3b62b5fea995580f837f87fc12',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://dea.sg/',
  //   harvest: true,
  //   tokenPerBlock: `14.081790123456790123`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 102,
  //   tokenName: 'RSUN',
  //   image: 'RSUN.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.rsun,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xa782cab148a3d33498e7f85b0491d4f364609c22',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://risingsun.finance/',
  //   harvest: true,
  //   tokenPerBlock: `97.589157407`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 103,
  //   tokenName: 'TXL',
  //   image: 'TXL.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.txl,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x03643a66e0a121839d230364fab392aa583cb984',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://tixl.org/',
  //   harvest: true,
  //   tokenPerBlock: `0.330932291666666666`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 104,
  //   tokenName: 'SFUND',
  //   image: 'SFUND.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.sfund,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xce12fb8d5e3f1acb469c8b9f657fd8188ca21e87',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://seedify.fund/',
  //   harvest: true,
  //   tokenPerBlock: `0.040509259259259259`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 105,
  //   tokenName: 'PHX',
  //   image: 'PHX.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.phx,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x05db8d4a67d293b43aa1db8cf38850fe790dbf29',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.phx.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.636574074074074074`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 106,
  //   tokenName: 'POTS',
  //   image: 'POTS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.pots,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x84cda09920232b5b138180b55759b218bf243645',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://moonpot.com ',
  //   harvest: true,
  //   tokenPerBlock: `0.013611111111111111`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 107,
  //   tokenName: 'FRMx',
  //   image: 'FRMx.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.frmx,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xac19462e8c9207cd1613292b93b5f7c65f1a597a',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://ferrum.network/',
  //   harvest: true,
  //   tokenPerBlock: `0.000011226851851851`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 108,
  //   tokenName: 'EXP',
  //   image: 'EXP.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.exp,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xde182dad837db2117e2981b1f94dec2cec8191cc',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://gamexchange.app/',
  //   harvest: true,
  //   tokenPerBlock: `21.043771043771041666`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 109,
  //   tokenName: 'CFi',
  //   image: 'CFi.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.cfi,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x502b015f0c4493e1e6414de471041347c58b0d97',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://cyberfi.tech/',
  //   harvest: true,
  //   tokenPerBlock: `.010416666666666666`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 110,
  //   tokenName: 'SX',
  //   image: 'SX.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.sx,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xc6215d86a4bb470667d54c316624a3c45504bc31',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.sx.technology/',
  //   harvest: true,
  //   tokenPerBlock: `0.263310185185185185`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 111,
  //   tokenName: 'ROCKI',
  //   image: 'ROCKS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.rocks,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x5d8b37C42c34CA1661dB227B5484726d89040702',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.rocki.app/',
  //   harvest: true,
  //   tokenPerBlock: `0.083964583333333333`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 112,
  //   tokenName: 'GUMMY',
  //   image: 'GUMMY.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.gummy,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xc8ff8674a03672c36d6b5e2cbbf8f7967b14aef6',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://gummybull.io/',
  //   harvest: true,
  //   tokenPerBlock: `1.374421296296296`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 113,
  //   tokenName: 'DCB',
  //   image: 'DCB.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.dcb,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x7b4459a0ce06f2ebb8b145add6620ae06ff225ed',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://decubate.com/',
  //   harvest: true,
  //   tokenPerBlock: `1.924189814814814814`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 114,
  //   tokenName: 'NFTY',
  //   image: 'NFTY.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.nfty,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x946a0e7b410cfc4e225a3e9f09a93259747b0903',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://nftynetwork.io/',
  //   harvest: true,
  //   tokenPerBlock: `6.944444444444444444`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 115,
  //   tokenName: 'AUR',
  //   image: 'AUR.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.aur,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x96034cd42b0eaa1a6dae20e1a13824aede3ba1f0',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://aurumofficial.net/',
  //   harvest: true,
  //   tokenPerBlock: `9.620949074074074074`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 116,
  //   tokenName: 'SEFI',
  //   image: 'SEFI.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.sefi,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x122365722070a9ca5d12040e511bb90f722b915c',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://secretswap.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.885127`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 6,
  // },
  // {
  //   sousId: 117,
  //   tokenName: 'KALA',
  //   image: 'KALA.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.kala,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x3902e6220c8425dcfb86118413481c6a625b28ba',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://app.kalata.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.060892361111111111`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 118,
  //   tokenName: 'IHC',
  //   image: 'IHC.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.ihc,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x155bd20237a70994364723e44416fd602b8d0991',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.ihcoin.io/',
  //   harvest: true,
  //   tokenPerBlock: `23.098493287037037037`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 119,
  //   tokenName: 'AIRT',
  //   image: 'AIRT.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.airt,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x3c765c830a082944f4b69052670d41cf5b513846',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.airnfts.com/',
  //   harvest: true,
  //   tokenPerBlock: `1.303447916666666666`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 120,
  //   tokenName: 'PROS',
  //   image: 'PROS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.pros,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x96d643e487e408c59c10b654976567aa2f1a16d3',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://prosper.so/',
  //   harvest: true,
  //   tokenPerBlock: `0.024564130208333333`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 121,
  //   tokenName: 'THG',
  //   image: 'THG.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.thg,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xcf44a7c1d8277b27c47195f0907a5b93e63d3dd0',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://thetanarena.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.025462962962962962`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 122,
  //   tokenName: 'WSG',
  //   image: 'WSG.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.wsg,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xde99115c3f8ee876b5520779665088f70f647a6d',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://wsg.gg/',
  //   harvest: true,
  //   tokenPerBlock: `570041.232638888888888888`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 124,
  //   tokenName: 'OTAKU',
  //   image: 'OTAKU.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.otaku,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xcfae247209b2f3c175ddaca619a7874447e534cd',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://fomochronicles.com/',
  //   harvest: true,
  //   tokenPerBlock: `1.316145833333333333`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 125,
  //   tokenName: 'TNNS',
  //   image: 'TNNS.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.tnns,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x8b82c8a380a4c7edd68a4df2d4b09a52b5704c3b',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.tnns.pro/',
  //   harvest: true,
  //   tokenPerBlock: `0.076967592592592592`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 126,
  //   tokenName: 'KING',
  //   image: 'KING.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.king,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x824b23f47da94055434242c1100dfa71cc691f83',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://cryptobladeskingdoms.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.128603009250000000`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 127,
  //   tokenName: 'MBIRD',
  //   image: 'MBIRD.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.mbird,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x0a935f40a288cb67ee86565a2b1113f5c7fe481d',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.moonbird.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.05787037037037037`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 128,
  //   tokenName: 'BBNANA',
  //   image: 'BBNANA.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.bbnana,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x0f1c37bea4984cf57e850929e839782e33790c8d',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://babybanana.finance/',
  //   harvest: true,
  //   tokenPerBlock: `28.862847222222222222`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 129,
  //   tokenName: 'TOWER',
  //   image: 'TOWER.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.tower,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x07f874ad2b3546164a33f4b6c27494ac9c1d3323',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://www.crazydefenseheroes.com/',
  //   harvest: true,
  //   tokenPerBlock: `1.11158521412037037`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 130,
  //   tokenName: 'OASIS',
  //   image: 'OASIS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.oasis,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x9B5919a87d727e17A1fa9116E4547A52A7f159ea',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://projectoasis.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.016855902777777777`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 131,
  //   tokenName: 'TOON',
  //   image: 'TOON.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.toon,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x1e20b8629602a28065f1daacf6155456063de290',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://pontoon.fi/',
  //   harvest: true,
  //   tokenPerBlock: `0.16534375`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 132,
  //   tokenName: 'WING',
  //   image: 'WING.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.wing,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x656e0f20509b3eb04dbccae5a0e6c2a08e615e20',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://wing.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.002113985`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 133,
  //   tokenName: 'GAN',
  //   image: 'GAN.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.gan,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xea5b77b22bd939b8196587d2bb9d8b5e0e497b74',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://galacticarena.io/',
  //   harvest: true,
  //   tokenPerBlock: `9.645061728395061728`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 134,
  //   tokenName: 'BCPAY',
  //   image: 'BCPAY.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.bcpay,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xb83D0cE7335CaF7c59e7B9727916C0e98b74cb49',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://bitcashpay.net/',
  //   harvest: true,
  //   tokenPerBlock: `0.48104745`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 8,
  // },
  // {
  //   sousId: 135,
  //   tokenName: 'iBFR',
  //   image: 'iBFR.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.ibfr,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x004D7f0661fF6e1420fE311Bca57C1D89C9751BA',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.buffer.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.342086298263888888`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 136,
  //   tokenName: 'SATA',
  //   image: 'SATA.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.sata,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x4AC61236F70bC24eDf6be97746672ab564641258',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://sata.technology/',
  //   harvest: true,
  //   tokenPerBlock: `0.115451388888888888`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 137,
  //   tokenName: 'LICO',
  //   image: 'LICO.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.lico,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x7077d94ca36cdcce214a9073fe9e4a865332bfc2',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://liquidcollectibles.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.034722222222222222`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 138,
  //   tokenName: 'LIME',
  //   image: 'LIME.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.lime,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x2dC448eF0Af4cDc299D2085CBC439459243E14aE',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://imem.app/',
  //   harvest: true,
  //   tokenPerBlock: `0.507986111111111111`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 139,
  //   tokenName: 'FXS',
  //   image: 'FXS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.fxs,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x565478B15dDABCDAf8B42528a0bD6a1c04ce070b',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://frax.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.005772569444444444`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 140,
  //   tokenName: 'RELAY',
  //   image: 'RELAY.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.relay,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xd95bBDEDE9ECbC2410bd78a66ded971BF3E8ff47',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.relaychain.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.004498015873015873`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 141,
  //   tokenName: 'QUIDD',
  //   image: 'QUIDD.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.quidd,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x7de443badcfcf49417864f70fe7734b8144082f1',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://market.onquidd.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.047315827546296296`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 142,
  //   tokenName: 'MONI',
  //   image: 'MONI.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.moni,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xc69a306e50b8ee373155746a0b6d457af4035d4b',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://monstainfinite.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.031041859567901234`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 143,
  //   tokenName: 'INFO',
  //   image: 'INFO.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.info,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x2Af85A532859aF6f30076197D91eEEb593C0FeB1',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://infomatix.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.641396527777777800`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 144,
  //   tokenName: 'SHILL',
  //   image: 'SHILL.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.shill,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x5E4Aa216B9e748e83D02b1F0334A0B8EB5ee9c32',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://projectseed.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.018827314814814814`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 145,
  //   tokenName: 'LMN',
  //   image: 'LMN.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.lmn,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x387021bA95c80856Be8B061533c67b47b5c148a6',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://lemonn.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.040985243055555555`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 146,
  //   tokenName: 'SISTA',
  //   image: 'SISTA.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.sista,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xd4b688873df6af18f5fd9e226730adcce635f5eb',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://srnartgallery.com',
  //   harvest: true,
  //   tokenPerBlock: `1.736111496913580246`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 147,
  //   tokenName: 'HERA',
  //   image: 'HERA.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.hera,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xB49baE5D0230A3D57C6CAFEab71a418CF62e4813',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://heroarena.app/',
  //   harvest: true,
  //   tokenPerBlock: `0.023090277777777777`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 148,
  //   tokenName: 'GMEE',
  //   image: 'GMEE.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.gmee,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x61713875F0759803DD1C7e2a83ACd11265Ff80BB',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.gamee.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.276112654320987654`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 149,
  //   tokenName: 'ARV',
  //   image: 'ARV.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.arv,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xc00EAbba05a314E52653fAB3fdB4861C7B11535F',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://ariva.digital/',
  //   harvest: true,
  //   tokenPerBlock: `74.00730015`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 8,
  // },
  // {
  //   sousId: 150,
  //   tokenName: 'rASKO',
  //   image: 'rASKO.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.rasko,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xC4527Fb81764E632509234cE78F5C91cc86C7162',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://asko.finance/rasko',
  //   harvest: true,
  //   tokenPerBlock: `4.675632804232803902`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 151,
  //   tokenName: 'PEL',
  //   image: 'PEL.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.pel,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xEc197AA9202a9111618BaA7a23b6A7Cd7B6Ea7d7',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://propel.xyz/',
  //   harvest: true,
  //   tokenPerBlock: `0.009620949074074074`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 152,
  //   tokenName: 'STZ',
  //   image: 'STZ.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.stz,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xe505e0142e3ea84e4e6ed006d9b264688374ec7b',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://99starz.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.033482253086419753`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 153,
  //   tokenName: 'QA',
  //   image: 'QA.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.qa,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x008fd008A139187aD57DD7E030BE00252E3eD757',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://quantumassets.vg/',
  //   harvest: true,
  //   tokenPerBlock: `1.199604552469135802`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 154,
  //   tokenName: 'LOA',
  //   image: 'LOA.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.loa,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x45d94C84cd5d150F1D6037f35f4dcaA6e1369ae8',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.leagueofancients.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.061574074074074074`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 155,
  //   tokenName: 'ADS',
  //   image: 'ADS.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.ads,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x66a17DAcE2d77f036422BDD50F3A20579613f5C0',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://adshares.net/',
  //   harvest: true,
  //   tokenPerBlock: `0.00902777777`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 11,
  // },
  // {
  //   sousId: 156,
  //   tokenName: 'KAI',
  //   image: 'KAI.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.kai,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x3b15B487902Bb10Cba34c489Cb35AC342F73Cff2',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://kardiachain.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.82740162037037037`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 157,
  //   tokenName: 'DOSE',
  //   image: 'DOSE.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.dose,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xd0378c1b37D530a00E91764A7a41EfEB3d6A5fbC',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://dosetoken.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.299904899691358024`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 158,
  //   tokenName: 'ORE',
  //   image: 'ORE.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.ore,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x633dd43b5f056ab9362162894f1e451f4d704681',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://outrace.game/',
  //   harvest: true,
  //   tokenPerBlock: `1.92418981`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 8,
  // },
  // {
  //   sousId: 159,
  //   tokenName: 'MYRA',
  //   image: 'MYRA.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.myra,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x18d9d84e6649fe0f3e60c338ff5a9e96d5f85702',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://mytheria.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.036078559027777777`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 160,
  //   tokenName: 'GODZ',
  //   image: 'GODZ.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.godz,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x8b91f9584dD43Ba4aEd7729b33aA1D459af1c5cc',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://cryptogodz.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.057725694444444444`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 161,
  //   tokenName: 'MNFT',
  //   image: 'MNFT.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.mnft,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xe4d4ffd1c58d21858f0a8cf915aa2b422740328d',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://manufactory.gg',
  //   harvest: true,
  //   tokenPerBlock: `1.92901234375`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 162,
  //   tokenName: 'GMR',
  //   image: 'GMR2.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.gmr,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x8966eda482ca1559cfea937da58f217fd1f2a468',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://gmr.center/',
  //   harvest: true,
  //   tokenPerBlock: `0.30365296803652968`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 163,
  //   tokenName: 'ONT',
  //   image: 'ONT.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.ont,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xb28d423765c7c906a3e55af54cea716c97df1f8d',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://ont.io',
  //   harvest: true,
  //   tokenPerBlock: `0.027330864197530868`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 164,
  //   tokenName: 'ONG',
  //   image: 'ONG.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.ong,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xc573890a14b3dddffade9a2bd7e6bada160724b6',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://ont.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.014892104`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 165,
  //   tokenName: 'NAUT',
  //   image: 'NAUT.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.naut,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xcFA6CAEd7E2eCB4411254B1444E31dE43824b3E8',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://astronaut.to/',
  //   harvest: true,
  //   tokenPerBlock: `0.01569357`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 8,
  // },
  // {
  //   sousId: 166,
  //   tokenName: 'ZIG',
  //   image: 'ZIG.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.zig,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xEbd0Cd94DC2BE20575EcdC0370a7d9A2c1AeD40c',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://zignaly.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.25076929012345679`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 167,
  //   tokenName: 'DEP',
  //   image: 'DEP.png',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.dep,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xca676dfEbcfC16bF0aA10e7D6291E92F69c61f50',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://dea.sg/',
  //   harvest: true,
  //   tokenPerBlock: `0.503472222222222222`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 168,
  //   tokenName: 'MOONLIGHT',
  //   image: 'MOONLIGHT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.moonlight,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xaaF270fb5Ffa43ea2F49DE261E805D1efF06FDEE',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://moonlighttoken.com',
  //   harvest: true,
  //   tokenPerBlock: `2601273.148150000`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 169,
  //   tokenName: 'LAND',
  //   image: 'LAND.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.land,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x4CA13C1b85211aBC06455a164A15a2Ef6a1733E0',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://landshare.io/',
  //   harvest: true,
  //   tokenPerBlock: `0.003325810185185185`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 170,
  //   tokenName: 'SPACE',
  //   image: 'SPACE.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.space,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x31BA18dF9a445D2E01Aa55B393cde86b149CBC4A',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.aperocket.finance/',
  //   harvest: true,
  //   tokenPerBlock: `0.005246913580246913`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 171,
  //   tokenName: 'WATCH',
  //   image: 'WATCH.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.watch,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xb0329d2A9BA4BC1ff7D4944412EF8DF0D4bDF304',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://yieldwatch.net/',
  //   harvest: true,
  //   tokenPerBlock: `0.079089506172839506`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 172,
  //   tokenName: 'LMT',
  //   image: 'LMT.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.lmt,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x9f3Fa4d728F26C9bb53a0641C51A5657544E257A',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://lympo.io/lmt/',
  //   harvest: true,
  //   tokenPerBlock: `0.395784143518518518`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 173,
  //   tokenName: 'GMR',
  //   image: 'GMR2.svg',
  //   stakingToken: tokens.gnana,
  //   rewardToken: tokens.gmr,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x24CD8C40d0B47Fd0E12D03A0d56fFfD44dD31f3F',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://gmr.center/',
  //   harvest: true,
  //   tokenPerBlock: `0.290167824074074074`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 174,
  //   tokenName: 'TXL',
  //   image: 'TXL.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.txl,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x57f626abfc72b914e68def65c3e1f27a59225cc3',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://tixl.org/',
  //   harvest: true,
  //   tokenPerBlock: `0.09611304012`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 175,
  //   tokenName: 'BCMC',
  //   image: 'BCMC.png',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.bcmc,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xb1c0a6518b766809dac889f2e49134864ea2cb57',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://bcmhunt.com/',
  //   harvest: true,
  //   tokenPerBlock: `0.033096064814814814`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 176,
  //   tokenName: 'ARI',
  //   image: 'ARI.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.ari,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x00262752032F07C51216B57909F175C972002393',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://www.manarium.com/',
  //   harvest: true,
  //   tokenPerBlock: `1.154513888888888888`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 177,
  //   tokenName: 'NFTY',
  //   image: 'NFTY2.svg',
  //   stakingToken: tokens.banana,
  //   rewardToken: tokens.nfty2,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0x0F555e92e9470d064F8A6D0b97a2E3F7d26A222d',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://nftytoken.io/ ',
  //   harvest: true,
  //   tokenPerBlock: `0.073888888888888888`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // //
  // // PUT ALL ADMIN POOLS UNDER HERE
  // //
  // {
  //   sousId: 123,
  //   tokenName: 'CYT',
  //   image: 'CYT.svg',
  //   stakingToken: tokens.obie,
  //   rewardToken: tokens.cyt,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xcd40fbe9eabc34faa59341c36c628e8867a22943',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: 'https://twitter.com/obie_Dobo/',
  //   harvest: true,
  //   tokenPerBlock: `0.11574074074074074`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   forAdmins: true,
  // },
  // {
  //   sousId: 96,
  //   tokenName: 'BANANA',
  //   image: 'BANANA.svg',
  //   stakingToken: tokens.obie,
  //   rewardToken: tokens.banana,
  //   contractAddress: {
  //     97: '0x6c6248ea3ff05200b9df0bc91cec2dea4f57a95d',
  //     56: '0xf1aFc0bfdf33BC74b9896612eAA38058cADfF8f0',
  //   },
  //   poolCategory: PoolCategory.APEZONE,
  //   projectLink: '',
  //   harvest: true,
  //   tokenPerBlock: `0.000011574074074074`,
  //   sortOrder: 1,
  //   reflect: false,
  //   isFinished: false,
  //   tokenDecimals: 18,
  //   forAdmins: true,
  // },
]

export default pools
