import { MenuEntry } from '@apeswapfinance/uikit'
import { CHAIN_ID, NETWORK_INFO_LINK } from 'config/constants/chains'
import { EXCHANGE, HOME, MORE_INFO } from '../constants'
export interface IMenuEntry extends MenuEntry {
  outSide?: boolean
}
const bscConfig: IMenuEntry[] = [
  HOME,
  EXCHANGE,
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Overview',
        href: '/info/overview',
      },
      {
        label: 'Tokens',
        href: `/info/tokens`,
      },
      {
        label: 'Pools',
        href: '/info/pools',
      },
    ],
  },
  {
    label: 'Earn',
    icon: 'EarnIcon',
    items: [
      {
        label: 'Oak barrel',
        href: '/earn/farms',
      },
      {
        label: 'Champagne',
        href: '/earn/pools/history',
      },
    ],
  },

  {
    label: 'NFT Launchpad',
    icon: 'LaunchPadIcon',
    href: '/nft-launchpad',
  },
  {
    label: 'NFT Earn',
    icon: 'NFTEarnIcon',
    items: [
      {
        label: 'NFT Staking',
        href: '/nft-staking',
      },
      {
        label: 'NFT Boost',
        href: '/nft-boost',
      },
      {
        label: 'Upgrade Level',
        href: '/nft-upgrade',
      },
    ],
  },

  {
    label: 'Win',
    icon: 'WinIcon',
    href: '/burn',
  },
  {
    label: 'IDO',
    icon: 'IfoIcon',
    href: '/ido',
  },
  {
    label: 'Vote Battle',
    icon: 'VoteBattleIcon',
    href: 'https://votebattle.winery.finance',
    outSide: true,
  },
  // {
  //   label: 'Airdrop',
  //   icon: 'AirplaneIcon',
  //   items: [
  //     {
  //       label: 'Aidrop Events',
  //       href: '/airdrops',
  //     },
  //     {
  //       label: 'Latest Airdrops',
  //       href: '/airdrops/latests',
  //     },
  //     {
  //       label: 'FAQs',
  //       href: '/airdrops/faqs',
  //     },
  //   ],
  // },
  {
    label: 'Referral',
    icon: 'EarnIcon',
    items: [
      {
        label: 'Referral',
        href: '/referral',
      },
      {
        label: 'Ranking',
        href: '/referral/ranks',
      },
    ],
  },
  {
    label: 'Marketplace',
    icon: 'MarketIcon',
    items: [
      {
        label: 'Explore',
        href: '/market',
      },
      // {
      //   label: 'Ranking',
      //   href: '/market/ranks',
      // },

      {
        label: 'OCADI Gallery',
        href: '/market/ocadi-gallery',
      },
    ],
  },
  // {
  //   label: 'Settings',
  //   icon: 'SettingsIcon',
  //   href: '/settings',
  // },

  // {
  //   label: 'IAO',
  //   icon: 'IfoIcon',
  //   items: [
  //     {
  //       label: 'Official',
  //       href: '/iao',
  //     },
  //     // {
  //     //   label: 'Self-Serve',
  //     //   href: '/ss-iao',
  //     // },
  //   ],
  // },
  // {
  //   label: 'NFA',
  //   icon: 'apeNFTIcon',
  //   items: [
  //     {
  //       label: 'Collection',
  //       href: '/nft',
  //     },
  //     {
  //       label: 'Auction',
  //       href: '/auction',
  //     },
  //     {
  //       label: 'Staking',
  //       href: '/staking',
  //     },
  //   ],
  // },
  // {
  //   label: 'GNANA',
  //   icon: 'ApeZone',
  //   href: '/gnana',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/burn',
  // },

  MORE_INFO,
]

export default bscConfig
