import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { ApeSwapTheme } from '@apeswapfinance/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends ApeSwapTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
  }
  body {
    background: ${({ theme }) =>
      theme.isDark ? 'rgb(33,36,49)' : 'linear-gradient(131.34deg, #FCE0DF 24.48%, #FEE0F8 84.84%, #FEE0F8 84.84%) '};
    min-height:100vh;
    img {
      height: auto;
      max-width: 100%;
    }
  }
  .tooltip-hover{
    font-size: 26px; 
    line-height:1.5; 
    color: ${({ theme }) => theme.colors.text};
  }
  .tooltip-date-hover {
    font-size: 16px; 
    line-height:1.5; 
    color: ${({ theme }) => theme.colors.textLight};
    font-weight:400;
  }
  .grecaptcha-badge { 
    visibility: hidden;
}
`

export default GlobalStyle
