import { ChevronUpIcon, ResetCSS, useModal } from '@apeswapfinance/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import GuideConnectMobileModal from 'components/GuideConnectMobileModal'
import PopupPageModal from 'components/GuideConnectMobileModal/PopupPageModal'
import MarketingModalCheck from 'components/MarketingModalCheck'
import { CHAIN_ID } from 'config/constants/chains'
import useEagerConnect from 'hooks/useEagerConnect'
import useReferral from 'hooks/useReferral'
import React, { lazy, Suspense, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import {
  useFetchProfile,
  useFetchPublicData,
  useFetchTokenPrices,
  useNetworkChainId,
  useUpdateNetwork,
} from 'state/hooks'
import styled from 'styled-components'
import { createHash } from 'utils'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import ToastListener from './components/ToastListener'
import GlobalStyle from './style/Global'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('views/Home'))
const Farms = lazy(() => import('views/Farms'))
const Pools = lazy(() => import('views/Pools'))
const Ifos = lazy(() => import('views/Ifos'))
const IfoDetail = lazy(() => import('views/Ifos/components/IfoDetail'))
const NotFound = lazy(() => import('views/NotFound'))
const DualFarms = lazy(() => import('views/DualFarms'))
const Nft = lazy(() => import('views/Nft'))
const Nfa = lazy(() => import('views/Nft/Nfa'))
const ApeZone = lazy(() => import('views/ApeZone'))
const Stats = lazy(() => import('views/Stats'))
const Auction = lazy(() => import('views/Auction'))
const BurningGames = lazy(() => import('views/BurningGames'))
const Iazos = lazy(() => import('views/Iazos'))
const CreateIazo = lazy(() => import('views/Iazos/components/CreateIazo'))
const IazoPage = lazy(() => import('views/Iazos/components/IazoPage'))
const AdminPools = lazy(() => import('views/AdminPools'))
const Vaults = lazy(() => import('views/Vaults'))
const NfaStaking = lazy(() => import('views/NfaStaking'))
const Info = lazy(() => import('views/Info'))
const AllTokens = lazy(() => import('views/Info/Tokens'))
const AllPools = lazy(() => import('views/Info/Pools'))
const TokenInfor = lazy(() => import('views/Info/Tokens/Token'))
const NftLaunchPad = lazy(() => import('views/NftLaunchPad'))
const NftStaking = lazy(() => import('views/NftEarn/NftStaking'))
const Upgrade = lazy(() => import('views/NftEarn/Upgrade'))
const NFTBoost = lazy(() => import('views/NftEarn/NFTBoost'))
const Refferal = lazy(() => import('views/Refferal'))
const ReferralRanking = lazy(() => import('views/Refferal/ReferralRanking'))
const Market = lazy(() => import('views/Market'))
const MarketRankings = lazy(() => import('./views/Market/Ranks'))
const MyNFTs = lazy(() => import('views/Market/MyNFTs'))
const NFTDetail = lazy(() => import('views/Market/NftDetail'))
const AllNFT = lazy(() => import('views/Market/AllNft'))
const Gallery = lazy(() => import('views/Market/Gallery'))
const NFTGalleryDetail = lazy(() => import('./views/Market/Gallery/NFTGalleryDetail'))
const MyGalleryNFTs = lazy(() => import('./views/Market/Gallery/MyGalleryNFT'))
const AirdropsDetailEvent = lazy(() => import('./views/Airdrops/AirdropsDetail'))
const FaqAirdrop = lazy(() => import('./views/Airdrops/components/Faqs'))
const LatestAirdrop = lazy(() => import('./views/Airdrops/LatestAirdrops'))
const Airdrops = lazy(() => import('./views/Airdrops'))
const MemberTeam = lazy(() => import(`./views/MemberTeam`))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  position: fixed;
  bottom: 40px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.background};
  /* border: 1px solid ${({ theme }) => theme.colors.primary}; */
  border-radius: 50%;
  z-index: 1000;
  cursor: pointer;
`

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  const { account } = useWeb3React()

  let query = useQuery()

  const { addParentRef } = useReferral()
  const [onPresentGuideConnectMobile] = useModal(<GuideConnectMobileModal />)
  const [onPresentPopupPage] = useModal(<PopupPageModal.PopupPageModal />)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return null
    }

    const token = await executeRecaptcha('yourAction')
    return token
  }, [executeRecaptcha])

  const location = useLocation()

  useEffect(() => {
    const isShowedGuide = JSON.parse(window.sessionStorage.getItem('mobile-guide-connect'))
    // onPresentPopupPage()
    if (!isShowedGuide && isMobile) {
      onPresentGuideConnectMobile()
      window.sessionStorage.setItem('mobile-guide-connect', JSON.stringify(true))
    }
  }, [])

  useEffect(() => {
    const accessRef = async () => {
      dataLayer?.push({ event: 'wallet_connect', user_id: account })
      const refCode = query.get('ref')
      const token = await handleReCaptchaVerify()

      try {
        if (token) {
          const hash = createHash(account, refCode, token)
          addParentRef(refCode, hash, token)
        }
      } catch (e) {}
    }

    if (account) {
      accessRef()
    }
  }, [account, handleReCaptchaVerify])

  const appChainId = useNetworkChainId()

  useUpdateNetwork()
  useEagerConnect()
  useFetchTokenPrices()
  useFetchPublicData()
  useFetchProfile()

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  // useEffect(() => {
  //   onMaintaincePopup()
  // }, [])
  const loadMenu = () => {
    // Ether routes
    if (appChainId === CHAIN_ID.ETHEREUM || appChainId === CHAIN_ID.RINKEBY) {
      return (
        <Menu>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>

              <Route path="/earn/farms">
                <Farms />
              </Route>
              <Route path="/earn/pools">
                <Pools />
              </Route>

              {/* Redirects */}

              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Menu>
      )
    }
    // Default BSC routes
    return (
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route exact path="/nft">
              <Nft />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/info/overview">
              <Info />
            </Route>
            <Route path="/info/tokens">
              <AllTokens />
            </Route>
            {/* <Route path="/info/token/:address">
              <TokenInfor />
            </Route> */}
            <Route path="/info/pools">
              <AllPools />
            </Route>
            <Route path="/earn/farms">
              <Farms />
            </Route>
            <Route path="/earn/pools">
              <Pools />
            </Route>
            <Route path="/vaults">
              <Vaults />
            </Route>
            <Route path="/admin-pools">
              <AdminPools />
            </Route>
            <Route path="/ido/:id">
              <IfoDetail />
            </Route>
            <Route path="/ido">
              <Ifos />
            </Route>
            <Route path="/auction">
              <Auction />
            </Route>
            <Route exact path="/ss-iao">
              <Iazos />
            </Route>
            <Route path="/ss-iao/create">
              <CreateIazo />
            </Route>
            <Route path="/ss-iao/:id">
              <IazoPage />
            </Route>
            {/* <Route exact path="/nft">
              <Nft />
            </Route> */}
            <Route path="/nft/:id">
              <Nfa />
            </Route>
            <Route path="/staking">
              <NfaStaking />
            </Route>
            {/* <Route path="/gnana">
              <ApeZone />
            </Route> */}
            {/* <Route path="/stats">
              <Stats />
            </Route> */}
            <Route path="/burn">
              <BurningGames />
            </Route>
            <Route exact path="/nft-launchpad">
              <NftLaunchPad />
            </Route>
            <Route path="/nft-boost">
              <NFTBoost />
            </Route>
            <Route path="/nft-upgrade">
              <Upgrade />
            </Route>
            <Route path="/nft-staking">
              <NftStaking />
            </Route>
            <Route exact path="/referral">
              <Refferal />
            </Route>
            <Route path="/referral/ranks">
              <ReferralRanking />
            </Route>
            <Route path="/market/all">
              <AllNFT />
            </Route>
            <Route exact path="/market">
              <Market />
            </Route>

            {/* <Route exact path="/market/ranks">
              <MarketRankings />
            </Route> */}
            {/* 
            <Route path="/airdrops/detail-event/:slug">
              <AirdropsDetailEvent />
            </Route>

            <Route exact path="/airdrops">
              <Airdrops />
            </Route>
            <Route path="/airdrops/latests">
              <LatestAirdrop />
            </Route>
            <Route path="/airdrops/faqs">
              <FaqAirdrop />
            </Route> */}

            <Route exact path="/market/ocadi-gallery">
              <Gallery />
            </Route>

            <Route path="/nft-gallery-detail/:contract/:id">
              <NFTGalleryDetail />
            </Route>
            <Route path="/my-gallery-nft">
              <MyGalleryNFTs />
            </Route>
            <Route path="/my-nft">
              <MyNFTs />
            </Route>
            <Route path="/team-members">
              <MemberTeam />
            </Route>
            <Route path="/nft-detail/:contract/:id">
              <NFTDetail />
            </Route>
            {/* Redirect */}
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>

            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
    )
  }

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />

      {/* <MarketingModalCheck /> */}
      {(window.location.pathname === '/farms' ||
        window.location.pathname === '/pools' ||
        window.location.pathname === '/vaults' ||
        window.location.pathname === '/iazos') && (
        <StyledChevronUpIcon onClick={scrollToTop} onChange={(value) => console.log(value)} />
      )}
      {loadMenu()}
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
